.fruitsPaytableButtonBed {
  display: flex;
  height: 100%;
}
.fruitsPaytableButton {
  color: #ffff;
  background-color: rgb(0, 122, 82);
  border: 0.8vh solid;
  border-top-color: rgb(131, 127, 127);
  border-right-color: rgb(131, 127, 127);
  border-bottom-color: rgb(65, 62, 62);
  border-left-color: rgb(65, 62, 62);
  border-radius: 10px;
  flex: 1;
  margin: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: Pacifico;
  font-size: 2.5vh;
}
.fruitsPaytableButton:active {
  background-color: rgb(3, 151, 102);
}

.fruitsPaytableBed {
  background-color: #0fa;
  box-shadow: 0 0 2px #0fa, 0 0 2px #0fa, 0 0 5px #0fa, 0 0 10px #0fa,
    0 0 10px #0fa, 0 0 12px #0fa, 0 0 10px #0fa, 0 0 60px #0fa;
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
}

.fruitPaytableHeader {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.fruitPaytableHeaderNameBed {
  z-index: 1;
  flex: 9;
  display: flex;
  justify-content: center;
  color: #ff6600;
  text-shadow: 5px 5px 5px black;
  font-family: Pacifico;
  font-size: 8vh;
}
.fruitPaytableHeaderNameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fruitPaytableHeaderNameText {
}
.fruitPaytableCloseButtonBed {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.fruitPaytableCloseButton {
  color: red;
  background-color: #ffff;
  border: 1px solid red;
  border-radius: 50%;
  font-size: 30px;
  margin: 10px 12px;
}
.fruitPaytableCloseButton:active {
  color: rgb(202, 5, 5);
}

.fruitPaytableCenter {
  flex: 4;
  display: flex;
  flex-direction: row;
}
.fruitPaytableCenterLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 3px;
}
.fruitPaytableCenterCenter {
  background-color: rgb(184, 252, 229);
  border-radius: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 3px;
}
.fruitsSymbolPaytableCenterTop {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.fruitsSymbolPaytableCenterTopHeading {
  flex: 1;
  font-size: 1.8vw;
  font-family: Pacifico;
  padding-top: 2%;
  margin-bottom: -2%;
}
.fruitsSymbolPaytableCenterTopSymbolBed {
  flex: 3;
  display: flex;
  flex-direction: row;
}
.fruitsSymbolPaytableCenterTopSymbolLeft {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 6vw;
}
.fruitsSymbolPaytableCenterTopSymbolCenter {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.fruitsSymbolPaytableCenterTopSymbolRight {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 1.8vw;
  font-weight: 900;
}
.fruitsSymbolPaytableCenterBottom {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.fruitsSymbolPaytableCenterBottomHeading {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.fruitsSymbolPaytableCenterBottomHeadingLeft {
  flex: 1;
  font-size: 5vh;
  font-weight: 900;
}
.fruitsSymbolPaytableCenterBottomHeadingRight {
  flex: 1;
  display: flex;
}
.fruitsSymbolPaytableCenterBottomHeadingRightSymbol {
  font-size: 1.9vw;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fruitsSymbolPaytableCenterBottomHeadingRightText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fruitsSymbolPaytableCenterBottomSymbolBed {
  flex: 3;
  display: flex;
}
.fruitsSymbolPaytableCenterBottomSymbolLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 6vw;
}
.fruitsSymbolPaytableCenterBottomSymbolRight {
  flex: 1;
  display: flex;
  flex-direction: row;
  font-size: 1.8vw;
  font-weight: 900;
}
.fruitsSymbolPaytableCenterBottomSymbolRightLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fruitsSymbolPaytableCenterBottomSymbolRightRight {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fruitPaytableCenterRight {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.fruitPaytableFooter {
  flex: 2;
  display: flex;
  flex-direction: row;
}
.fruitsSymbolPaytableBottomBedLeft {
  background-color: rgb(184, 252, 229);
  border-radius: 10px;
  flex: 4;
  display: flex;
  flex-direction: row;
  margin: 5px;
  font-size: 7vw;
}
.fruitsSymbolPaytableBottomBedRight {
  background-color: rgb(184, 252, 229);
  border-radius: 10px;
  flex: 5;
  display: flex;
  flex-direction: row;
  margin: 5px;
  font-size: 6vw;
}
.fruitsSymbolPaytableBottomLeft {
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fruitsSymbolPaytableBottomSymbolBed {
  display: flex;
  justify-content: space-around;
}
.fruitsSymbolPaytableBottomCenter {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 1.8vw;
  font-weight: 900;
}
.fruitsSymbolPaytableLeftRight {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 1.8vw;
  font-weight: 900;
}

.fruitsSymbolPaytableCenterBed {
  background-color: rgb(184, 252, 229);
  border-radius: 10px;
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 2px;
}
.fruitsSymbolPaytableCenterLeft {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 7vw;
}
.fruitsSymbolPaytableCenterCenter {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 1.8vw;
  font-weight: 900;
}
.fruitsSymbolPaytablePoint {
  color: '#ffff';
  font-size: 2vh;
  margin: 0 8px 3px;
}
.fruitsSymbolPaytableCenterRight {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 1.8vw;
  font-weight: 900;
}
