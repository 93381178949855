.gamesMenuBackGroundBed {
  background: rgb(56, 0, 121);
  width: 100%;
  height: 100vh;
}

.gamesMenuBackGroundShapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gamesMenuBackGroundShapes li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.gamesMenuBackGroundShapes li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.gamesMenuBackGroundShapes li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.gamesMenuBackGroundShapes li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.gamesMenuBackGroundShapes li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.gamesMenuBackGroundShapes li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.gamesMenuBackGroundShapes li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.gamesMenuBackGroundShapes li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.gamesMenuBackGroundShapes li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.gamesMenuBackGroundShapes li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.gamesMenuBackGroundShapes li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.gamesMenuHeaderBarBed {
  display: flex;
  justify-content: space-between;
}
.gamesMenuHeaderBarCredits {
  color: #ffff;
  font-size: 20px;
  font-weight: 100;
  margin: 20px 0 0 20px;
  cursor: pointer;
}
.gamesMenuHeaderBarLogout {
  color: #ffff;
  font-size: 20px;
  font-weight: 100;
  margin: 20px 20px 0 0;
  cursor: pointer;
}
.gamesMenuBed {
  padding: 3px;
  position: absolute;
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.gamesMenuCenterBed {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.gamesMenuList {
  display: flex;
  justify-content: center;
}
.gamesMenuHeaderBed {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.gamesMenuHeading {
  color: #ffff;
  font-size: 40px;
  font-weight: 100;
}
.gamesMenuItemsBed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  cursor: pointer;
}
.gamesMenuItem {
  background-image: url('../../assets/images/gameButtons/fruits.png');
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #ffff;
  border-radius: 15px;
  width: 300px;
  height: 300px;
  text-align: center;
  margin: 10px;
}

.gameMenuButtonGameName {
  color: rgb(0, 179, 119);
  font-family: Pacifico;
  font-size: 70px;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
  padding-top: 40px;
}

.gamesMenuInsertCoinBed {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.gamesMenuInsertCoinText {
  color: #ffff;
  font-size: 4vh;
  animation: flicker 1s infinite;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
