.promoPrizeWheelBed {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.promoPrizeWheelContainer {
  display: flex;
  flex-direction: column;
}

.promoPrizeWheelHeaderBed {
  display: flex;
  justify-content: center;
  padding: 50px 0 50px;
  height: 30vh;
}

.promoPrizeWheelHeaderText {
  color: rgb(255, 255, 255);
  font-size: 7vh;
  font-weight: 800;
  font-family: 'Exo', sans-serif;
  text-align: center;
  margin-bottom: 48px;
}

.promoPrizeWheelResultHeaderText {
  color: rgb(129, 209, 1);
  font-size: 12vh;
  font-weight: 800;
  font-family: 'Exo', sans-serif;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 5vh;
  animation: flashColors 2s linear infinite;
}

@keyframes flashColors {
  0% {
    color: red;
  }
  16.67% {
    color: orange;
  }
  33.33% {
    color: yellow;
  }
  50% {
    color: green;
  }
  66.67% {
    color: blue;
  }
  83.33% {
    color: indigo;
  }
  100% {
    color: violet;
  }
}

.promoPrizeWheelSubHeader {
  color: rgb(255, 255, 255);
  font-size: 7vh;
  font-weight: 800;
  font-family: 'Exo', sans-serif;
  text-align: center;
}

.promoPrizeWheel {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.wheel {
  display: block;
  height: 60vh;
}

.spin {
  font: 1.5em/0 sans-serif;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 30%;
  margin: -15%;
  background: #fff;
  color: #fff;
  box-shadow: 0 0 0 8px currentColor, 0 0px 15px 5px rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  transition: 0.8s;
}

.spin::after {
  content: '';
  position: absolute;
  top: -17px;
  border: 10px solid transparent;
  border-bottom-color: currentColor;
  border-top: none;
}
