.jackpotOverlayBed {
  background-color: rgba(0, 0, 0, 0.836);
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3px;
}

/* hidden div  */
.jackpotOverlayTop {
  flex: 3;
}
.jackpotOverlayMiddle {
  flex: 13;
  display: flex;
  flex-direction: column;
}

.jackpotOverlayMiddleTop {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.jackpotOverlayTopLine {
  height: 100%;
}
.jackpotOverlayTopLineHidden {
  height: 100%;
}

.jackpotOverlayMiddleCenter {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.jackpotOverlayMiddleLine {
  height: 100%;
}
.jackpotOverlayMiddleLineHidden {
  height: 100%;
}

.jackpotOverlayMiddleBottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.jackpotOverlayBottomLine {
  height: 100%;
}
.jackpotOverlayBottomLineHidden {
  height: 100%;
}

.jackpotOverlayAnimationBed {
  height: 100%;
  display: flex;
}

.jackpotOverlayTextBed {
  z-index: 3;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 100%;
}
.jackpotOverlayTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 17vh;
}
.jackpotOverlayText {
  color: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  font-size: 12vh;
  font-family: Chunk;
  animation: text 5s ease infinite;
}
@keyframes text {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
    color: #ff000d;
  }
  25% {
    opacity: 1;
    color: #98c4ff;
  }
  50% {
    opacity: 1;
    color: #fffc49;
  }
  75% {
    opacity: 1;
    color: #16ffec;
  }
  100% {
    opacity: 1;
    color: #ff77ab;
  }
}

.jackpotOverlayBlinkDots {
  font-size: 30vh;
  text-align: center;
}
.blink {
  animation: test 0.3s infinite alternate;
  animation-timing-function: ease-in;
}
#dot1 {
  color: rgb(0, 89, 255);
  animation-delay: 0.3s;
}
#dot2 {
  color: rgb(0, 255, 0);
  animation-delay: 0.1s;
}
#dot3 {
  color: #ff000d;
  animation-delay: 0.2s;
}

@keyframes test {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* hidden div */
.jackpotOverlayBottom {
  flex: 4;
}
