.facesBed {
  background-color: rgb(3, 187, 187);
  padding: 3px;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.facesTopRow {
  background-color: blueviolet;
  z-index: 1;
  flex: 3;
  display: flex;
  flex-direction: row;
}

/* middle row */
.facesMiddleRow {
  background-color: brown;
  flex: 30;
  display: flex;
  flex-direction: row;
}
/*middle left*/
.facesMiddleLeftBed {
  background-color: chartreuse;
  flex: 15;
  display: flex;
  flex-direction: column;
}
/*middle center*/
.facesReelsBed {
  background-color: crimson;
  flex: 25;
  display: flex;
  flex-direction: row;
}
.facesReelsCenterBed {
  flex: 26;
}
.facesReelsCenterContainer {
  background-color: aliceblue;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.facesReel {
  background-color: #ffff;
  flex: 1;
  margin: 0.4vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.facesReelSymbol {
  font-size: 13vh;
}
/*middle right*/
.facesMiddleRightBed {
  background-color: blue;
  flex: 15;
  display: flex;
  flex-direction: column;
}

.facesBottomRow {
  background-color: cornflowerblue;
  z-index: 1;
  flex: 4;
  display: flex;
  flex-direction: row;
}
