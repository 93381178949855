.slotBed {
  background-color: rgb(77, 43, 0);
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.slotHeaderBed {
  background-color: rgb(77, 43, 0);
  opacity: 1;
  background-image: linear-gradient(0deg, #5e2200 50%, #994a00 50%);
  background-size: 4px 4px;
  z-index: 1;
  flex: 3;
  display: flex;
  flex-direction: row;
}
.slotHeaderLeftBed {
  flex: 5;
  display: flex;
  flex-direction: row;
}
.creditsBed {
  background-color: black;
  flex: 2;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  flex-direction: column;
}
.creditsHeading {
  color: #ffff;
  flex: 1;
  text-align: center;
  margin-top: 2vh;
  font-family: Pacifico;
  font-size: 3vh;
}
.creditsAmount {
  color: #ffff;
  flex: 1;
  text-align: center;
  font-family: Dites;
  font-weight: 400;
  font-size: 5vh;
}
.paytableBed {
  flex: 1;
}

.amountWonBed {
  color: #ffff;
  background-color: #000000;
  height: 90%;
  margin-top: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  animation: blinkAmountWon 0.5s linear infinite;
}
@keyframes blinkAmountWon {
  from {
    background-color: #ffff;
  }
  to {
    background-color: #000000;
  }
}

.amountWonContainer {
  font-family: Dites;
  font-weight: 600;
  font-size: 3.5vh;
  line-height: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.slotNameBed {
  flex: 10;
  display: flex;
  justify-content: center;
}
.slotNameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.slotNameText {
  color: rgb(0, 179, 119);
  font-family: Pacifico;
  font-size: 12vh;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
    0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
}
.jackpotBed {
  flex: 5;
  display: flex;
  flex-direction: row;
}
.jackpotContainer {
  background-color: black;
  flex: 5;
  margin: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.jackpotHeading {
  color: #ffff;
  flex: 1;
  text-align: center;
  margin-top: 2vh;
  font-family: Pacifico;
  font-size: 3vh;
}
.jackpotAmount {
  color: #ffff;
  flex: 1;
  text-align: center;
  font-family: Dites;
  font-size: 5vh;
}
.fruitsExitButtonBed {
  flex: 1;
  flex-direction: row;
  justify-content: center;
}
.fruitsExitButton {
  color: #000000;
  background-color: #ffff;
  border: 1px solid rgb(39, 1, 1);
  border-radius: 25px;
  font-size: 3vw;
  margin: 1px 0 0 7px;
  cursor: pointer;
}
.fruitsExitButton:active {
  color: #202020;
}

.reelsBed {
  background-color: rgb(77, 43, 0);
  opacity: 1;
  background-image: linear-gradient(0deg, #5e2200 50%, #994a00 50%);
  background-size: 4px 4px;
  flex: 13;
  display: flex;
  flex-direction: row;
}
.reelsCenterContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.reelSpinning {
  background-color: #e6e6e6;
  flex: 1;
  margin: 0.4vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.reelStatic {
  background-color: #ffffff;
  opacity: 1;
  background-image: repeating-linear-gradient(
      45deg,
      #e6e6e6 25%,
      transparent 25%,
      transparent 75%,
      #e6e6e6 75%,
      #e6e6e6
    ),
    repeating-linear-gradient(
      45deg,
      #e6e6e6 25%,
      #ffffff 25%,
      #ffffff 75%,
      #e6e6e6 75%,
      #e6e6e6
    );
  background-position: 0 0, 4px 4px;
  background-size: 8px 8px;
  flex: 1;
  margin: 0.4vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.reelSymbolBed {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.reelSymbolBedWinRowA {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: blinkA 0.5s linear infinite;
}
@keyframes blinkA {
  from {
    background-color: #ffff;
  }
  to {
    background-color: rgb(0, 0, 255);
  }
}
.reelSymbolBedWinRowB {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: blinkB 0.5s linear infinite;
}
@keyframes blinkB {
  from {
    background-color: #ffff;
  }
  to {
    background-color: rgb(0, 255, 0);
  }
}
.reelSymbolBedWinRowC {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: blinkC 0.5s linear infinite;
}
@keyframes blinkC {
  from {
    background-color: #ffff;
  }
  to {
    background-color: rgb(255, 0, 0);
  }
}

.reelSymbolContainerStop {
  display: flex;
  justify-content: center;
  animation: stop 0.5s ease-out forwards;
}
.reelSymbolContainerSpin {
  display: flex;
  justify-content: center;
  animation: spin 0.2s linear infinite;
}
.reelSymbol {
  font-size: 17vh;
}

@keyframes spin {
  0% {
    transform: translateY(130px);
  }
  100% {
    transform: translateY(-200px);
  }
}

@keyframes stop {
  0% {
    transform: translateY(90px);
  }
  50% {
    transform: translateY(-5px);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

.reelsBorderLeftBed {
  flex: 10;
  display: flex;
  flex-direction: column;
}

.reelsBorderLeftRightBlockHidden {
  flex: 1;
}
.reelsBorderLeftRightBlock1 {
  background-color: rgb(0, 0, 255);
  border: 0.7vh solid rgb(86, 86, 255);
  border-radius: 10px;
  margin: 0.1vh 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.reelsBorderLeftRightBlock1Animate {
  background-color: rgb(0, 0, 255);
  border: 0.7vh solid rgb(86, 86, 255);
  border-radius: 10px;
  margin: 0.1vh 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.reelsBorderLeftRightBlock1Animate {
  animation: animateSideBlocks 1.3s infinite;
}
.reelsBorderLeftRightBlock2 {
  background-color: rgb(0, 255, 0);
  border: 0.7vh solid rgb(71, 255, 71);
  border-radius: 10px;
  margin: 0.1vh 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.reelsBorderLeftRightBlock2Animate {
  background-color: rgb(0, 255, 0);
  border: 0.7vh solid rgb(71, 255, 71);
  border-radius: 10px;
  margin: 0.1vh 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.reelsBorderLeftRightBlock2Animate {
  animation: animateSideBlocks 1.3s infinite;
}
.reelsBorderLeftRightBlock3 {
  background-color: rgb(255, 0, 0);
  border: 0.7vh solid rgb(255, 72, 72);
  border-radius: 10px;
  margin: 0.1vh 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.reelsBorderLeftRightBlock3Animate {
  background-color: rgb(255, 0, 0);
  border: 0.7vh solid rgb(255, 72, 72);
  border-radius: 10px;
  margin: 0.1vh 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.reelsBorderLeftRightBlock3Animate {
  animation: animateSideBlocks 1.3s infinite;
}
.reelsBorderLeftRightBlockText {
  z-index: 1;
  font-family: Pacifico;
  font-size: 6vh;
}
.reelsBorderLeftRightBlockIcon {
  color: #ffff;
  background-color: #3e3e3f;
  border-radius: 50%;
}
@keyframes animateSideBlocks {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.reelsCenterBed {
  flex: 26;
}
.reelsBorderRightBed {
  flex: 10;
  display: flex;
  flex-direction: column;
}

.footerBed {
  background-color: rgb(77, 43, 0);
  opacity: 1;
  background-image: linear-gradient(0deg, #5e2200 50%, #994a00 50%);
  background-size: 4px 4px;
  z-index: 1;
  flex: 4;
  display: flex;
  flex-direction: row;
}
.betSizeBed {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.betSizeContainer {
  background-color: black;
  border: 1px solid black;
  border-radius: 10px;
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.betSizeAmountBed {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.betSizeAmountContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.betSizeAmountText {
  color: #ffff;
  font-family: Dites;
  font-size: 6vh;
  font-weight: 300;
}
.betSizeButtonBed {
  background-color: #02236b;
  border: 1.2vh solid;
  border-top-color: rgb(131, 127, 127);
  border-right-color: rgb(131, 127, 127);
  border-bottom-color: rgb(65, 62, 62);
  border-left-color: rgb(65, 62, 62);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex: 4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
.betSizeButtonBed:active {
  background-color: #021d58;
}
.betSizeButtonContainer {
  color: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Pacifico;
  font-size: 4vh;
}

.freeSpinPlaceholderBed {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.freeSpinPlaceholderContainer {
  background-color: black;
  border: 1px solid black;
  border-radius: 10px;
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.freeSpinPlaceholderAmountBed {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.freeSpinPlaceholderAmountContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.freeSpinPlaceholderAmountText {
  color: #ffff;
  font-family: Dites;
  font-size: 6vh;
  font-weight: 300;
}
.freeSpinPlaceholderButtonBed {
  background-color: #00575a;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex: 4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  animation: freeSpinPlaceHoder 5s ease infinite;
}
@keyframes freeSpinPlaceHoder {
  0% {
    opacity: 1;
    background-color: #ff9100;
  }
  10% {
    opacity: 1;
    background-color: #ff000d;
  }
  25% {
    opacity: 1;
    background-color: #98c4ff;
  }
  50% {
    opacity: 1;
    background-color: #fffc49;
  }
  75% {
    opacity: 1;
    background-color: #16ffec;
  }
  100% {
    opacity: 1;
    background-color: #ff77ab;
  }
}
.freeSpinPlaceholderButtonContainer {
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: dites;
}
.freeSpinPlaceholderText {
  font-size: 4vh;
  font-weight: 700;
  text-align: center;
  line-height: 4vh;
}

.betTotalBed {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.betTotalContainer {
  background-color: black;
  border: 1px solid black;
  border-radius: 10px;
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.betTotalAmountBed {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.betTotalAmountContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.betTotalAmountText {
  color: #ffff;
  font-family: Dites;
  font-size: 6vh;
  font-weight: 300;
}
.betTotalButtonBed {
  background-color: #02646b;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex: 4;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.betTotalButtonContainer {
  color: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Pacifico;
  font-size: 4vh;
}

.maxBetButtonBed {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  outline: none;
}
.maxBetButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.maxBetButton {
  color: #ffff;
  background-color: rgb(163, 0, 0);
  border: 1.5vh solid;
  border-top-color: rgb(131, 127, 127);
  border-right-color: rgb(131, 127, 127);
  border-bottom-color: rgb(65, 62, 62);
  border-left-color: rgb(65, 62, 62);
  border-radius: 10px;
  cursor: pointer;
}
.maxBetButton:active {
  background-color: rgb(131, 3, 3);
}
.maxBetButtonText {
  text-align: center;
  font-family: Pacifico;
  font-size: 4vh;
  padding: 20px;
  line-height: 40px;
}

.betTotalText {
  font-size: 3vh;
  font-weight: 900;
  text-align: center;
  line-height: 4vh;
}

.resultBed {
  flex: 5;
  display: flex;
}
.resultContainer {
  background-color: black;
  flex: 1;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.bannerGif {
  max-height: 17.5vh;
}
.resultTextBed {
  color: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Dites;
  font-size: 5vh;
  font-weight: 300;
}
.autoSpinButtonBed {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.autoSpinButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.autoSpinButtonStop {
  color: #ffff;
  background-color: rgb(163, 0, 0);
  border: 1.5vh solid;
  border-top-color: rgb(131, 127, 127);
  border-right-color: rgb(131, 127, 127);
  border-bottom-color: rgb(65, 62, 62);
  border-left-color: rgb(65, 62, 62);
  border-radius: 10px;
  cursor: pointer;
}
.autoSpinButtonStart {
  color: #ffff;
  background-color: #02646b;
  border: 1.5vh solid;
  border-top-color: rgb(131, 127, 127);
  border-right-color: rgb(131, 127, 127);
  border-bottom-color: rgb(65, 62, 62);
  border-left-color: rgb(65, 62, 62);
  border-radius: 10px;
  cursor: pointer;
}

.autoSpinButtonText {
  text-align: center;
  font-family: Pacifico;
  font-size: 4vh;
  padding: 20px;
  line-height: 40px;
}
.spinButtonBed {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.spinButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.spinButton {
  color: #ffff;
  background-color: rgb(0, 117, 163);
  border: 1.5vh solid;
  border-top-color: rgb(131, 127, 127);
  border-right-color: rgb(131, 127, 127);
  border-bottom-color: rgb(65, 62, 62);
  border-left-color: rgb(65, 62, 62);
  border-radius: 10px;
  cursor: pointer;
}
.spinButton:active {
  background-color: rgb(1, 88, 122);
}
.spinButtonText {
  text-align: center;
  font-family: Pacifico;
  font-size: 4vh;
  padding: 20px;
  line-height: 40px;
}
