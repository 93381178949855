@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: 'Exo', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

@font-face {
  font-family: 'Pacifico';
  src: local('Pacifico'),
    url(../assets/fonts/pacifico/Pacifico.ttf) format('truetype');
}

@font-face {
  font-family: 'Dites';
  src: local('Dites'), url(../assets/fonts/dites//Dited.otf) format('truetype');
}

@font-face {
  font-family: 'Chunk';
  src: local('Chunk'), url(../assets/fonts/chunk//Chunk.otf) format('truetype');
}

@import url('https://use.typekit.net/yjs2lsy.css');

*,
*:before,
*:after {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}
::-moz-selection {
  background: transparent;
}
::selection {
  background: transparent;
}

.mobileRestrictedMessage {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 240, 29);
  padding: 20px;
  margin: 15px;
  border-radius: 10px;
  text-align: center;
  font-size: large;
}

.enterFullScreenButtonBed {
  background: rgb(56, 0, 121);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.enterFullScreenButtonContainer {
  display: flex;
  justify-content: center;
}

.enterFullScreenButton {
  background-color: rgb(50, 175, 0);
  border-radius: 10px;
  font-size: larger;
  padding: 20px;
  margin: 15px;
  cursor: pointer;
}
