.scatterWinOverlayBed {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3px;
}

/* hidden div  */
.scatterWinOverlayTop {
  flex: 3;
}
.scatterWinOverlayMiddle {
  flex: 13;
  display: flex;
  flex-direction: column;
}

.scatterWinOverlayMiddleTop {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.scatterWinOverlayTopLine {
  background-color: rgb(0, 0, 255);
  height: 100%;
}
.scatterWinOverlayTopLineHidden {
  background-color: rgba(0, 0, 0, 0.644);
  height: 100%;
}

.scatterWinOverlayMiddleCenter {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.scatterWinOverlayMiddleLine {
  background-color: rgb(0, 255, 0);
  height: 100%;
}
.scatterWinOverlayMiddleLineHidden {
  background-color: rgba(0, 0, 0, 0.644);
  height: 100%;
}

.scatterWinOverlayMiddleBottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.scatterWinOverlayBottomLine {
  background-color: rgb(255, 0, 0);
  height: 100%;
}
.scatterWinOverlayBottomLineHidden {
  background-color: rgba(0, 0, 0, 0.644);
  height: 100%;
}

.scatterWinOverlayAnimationBed {
  height: 100%;
  display: flex;
}
.scatterWinOverlayAnimationBellBed {
  z-index: 1;
  height: 100%;
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: bell 7s 1;
}
.scatterWinOverlayBell {
  font-size: 17vh;
}
@keyframes bell {
  0% {
    left: -10%;
  }
  30% {
    left: 90%;
  }
  50% {
    left: 75%;
  }
  75% {
    left: 75%;
  }
  100% {
    left: 75%;
  }
}
.scatterWinOverlayAnimationCloverBed {
  z-index: 2;
  height: 100%;
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: clover 7s 1;
}
.scatterWinOverlayClover {
  font-size: 17vh;
}
@keyframes clover {
  0% {
    right: -110%;
  }
  30% {
    right: 15%;
  }
  50% {
    right: 0%;
  }
  75% {
    right: 0%;
  }
  100% {
    right: 0%;
  }
}

.scatterWinOverlayTextBed {
  z-index: 3;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 100%;
}
.scatterWinOverlayTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.scatterWinOverlayText {
  color: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  font-size: 11vh;
  font-family: Chunk;
  animation: text 5s ease infinite;
}
@keyframes text {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
    color: #ff000d;
  }
  25% {
    opacity: 1;
    color: #98c4ff;
  }
  50% {
    opacity: 1;
    color: #fffc49;
  }
  75% {
    opacity: 1;
    color: #16ffec;
  }
  100% {
    opacity: 1;
    color: #ff77ab;
  }
}

.scatterWinOverlayBlinkDots {
  font-size: 30vh;
  text-align: center;
}
.blink {
  animation: test 0.3s infinite alternate;
  animation-timing-function: ease-in;
}
#dot1 {
  color: rgb(0, 89, 255);
  animation-delay: 0.3s;
}
#dot2 {
  color: rgb(0, 255, 0);
  animation-delay: 0.1s;
}
#dot3 {
  color: #ff000d;
  animation-delay: 0.2s;
}

@keyframes test {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* hidden div */
.scatterWinOverlayBottom {
  flex: 4;
}
