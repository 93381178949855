.lockScreenBed {
  padding: 3px;
  position: absolute;
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lockScreenContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.lockScreenIcon {
  color: #ffff;
  font-size: 20vh;
  margin: 30px;
}

.lockScreenText {
  color: rgba(255, 255, 255, 0.815);
  font-size: 2rem;
  text-align: center;
  margin-top: -20px;
}
