.linesOverlayBed {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3px;
}

/* hidden div  */
.linesOverlayTop {
  flex: 3;
}
.linesOverlayMiddle {
  flex: 13;
  display: flex;
  flex-direction: column;
}

.linesOverlayMiddleTop {
  flex: 1;
  margin: 0 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.linesOverlayTopLine {
  background-color: rgb(0, 0, 255);
  height: 2vh;
  margin: 0 5px 0 5px;
}
.linesOverlayTopLineHidden {
  margin: 0 5px 0 5px;
}

.linesOverlayMiddleCenter {
  flex: 1;
  margin: 0 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.linesOverlayMiddleLine {
  background-color: rgb(0, 255, 0);
  height: 2vh;
  margin: 0 5px 0 5px;
}
.linesOverlayMiddleLineHidden {
  margin: 0 5px 0 5px;
}

.linesOverlayMiddleBottom {
  flex: 1;
  margin: 0 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.linesOverlayBottomLine {
  background-color: rgb(255, 0, 0);
  height: 2vh;
  margin: 0 5px 0 5px;
}
.linesOverlayBottomLineHidden {
  margin: 0 5px 0 5px;
}

/* hidden div */
.linesOverlayBottom {
  flex: 4;
}
