.networkErrorOverlayBed {
  background-color: rgba(0, 0, 0, 0.521);
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3px;
}

/* hidden div  */
.networkErrorOverlayTop {
  flex: 3;
}
.networkErrorOverlayMiddle {
  flex: 13;
  display: flex;
  flex-direction: column;
}

.networkErrorOverlayMiddleTop {
  flex: 1;
  margin: 0 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.networkErrorOverlayTopLine {
  background-color: rgb(0, 0, 255);
  height: 2vh;
  margin: 0 5px 0 5px;
}
.networkErrorOverlayTopLineHidden {
  margin: 0 5px 0 5px;
}

.networkErrorOverlayMiddleCenter {
  flex: 3;
  margin: 0 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.networkErrorOverlayMessageBed {
  display: flex;
  justify-content: center;
}

.networkErrorOverlayMessageContainer {
  background-color: red;
  width: 50%;
  border: #ffff solid 7px;
  border-color: #ffff;
  border-radius: 10px;
  padding: 40px;
}

.networkErrorOverlayMessageText {
  color: #ffff;
  text-align: center;
  font-weight: 700;
  font-size: 3.3vh;
  line-height: 30px;
}

.networkErrorOverlayMiddleLine {
  background-color: rgb(0, 255, 0);
  height: 2vh;
  margin: 0 5px 0 5px;
}
.networkErrorOverlayMiddleLineHidden {
  margin: 0 5px 0 5px;
}

.networkErrorOverlayRefundButtonBed {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.networkErrorOverlayRefundButton {
  color: #ffff;
  background-color: rgb(39, 39, 39);
  font-size: 3vh;
  font-weight: 800;
  text-align: center;
  border-radius: 5px;
  padding: 15px 20px;
  cursor: pointer;
}

.networkErrorOverlayMiddleBottom {
  flex: 1;
  margin: 0 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.networkErrorOverlayBottomLine {
  background-color: rgb(255, 0, 0);
  height: 2vh;
  margin: 0 5px 0 5px;
}
.networkErrorOverlayBottomLineHidden {
  margin: 0 5px 0 5px;
}

/* hidden div */
.networkErrorOverlayBottom {
  flex: 4;
}
